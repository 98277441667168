import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, map, shareReplay, switchMap } from 'rxjs';
import { environment } from 'src/environments/environment';
import {
  ClientContractSummary,
  ClientContractSummaryDto
} from '../../models/client/contract/client-contract-summary.model';
import { ContractSaveDto } from '../../models/client/contract/contract-save.model';
import { PageParams } from '../../models/base/page/page-params.model';
import { Page, PageDto } from '../../models/base/page/page.model';
import HttpClientUtils from 'src/app/shared/utils/http-client-utils';
import {
  ClientContract,
  ClientContractDto
} from '../../models/client/contract/client-contract.model';
import { SelectedInterpreterDto } from '../../models/client/contract/selected-interpreter.model';
import { AdditionalInterpretersSaveDto } from '../../models/client/contract/additional-interpreters-save.model';

@Injectable({
  providedIn: 'root'
})
export class ClientContractDataService {
  protected readonly baseUrl = `${environment.apiRoot}/client/contract`;

  cacheInvalidator$ = new BehaviorSubject<void>(undefined);

  constructor(private httpClient: HttpClient) {}

  getPendingContracts(): Observable<ClientContractSummary[]> {
    return this.getContracts('pending');
  }

  getDrafts(): Observable<ClientContractSummary[]> {
    return this.getContracts('draft');
  }

  getCancelledContracts(): Observable<ClientContractSummary[]> {
    return this.getContracts('cancelled-today');
  }

  getFutureContracts(): Observable<ClientContractSummary[]> {
    return this.getContracts('future');
  }

  getArchivedContracts(params: PageParams): Observable<Page<ClientContractSummary>> {
    return this.httpClient
      .get<PageDto<ClientContractSummary>>(`${this.baseUrl}/archived`, {
        params: HttpClientUtils.toHttpParams(params)
      })
      .pipe(
        map(dto => new Page<ClientContractSummary>(dto, ClientContractSummary)),
        shareReplay({ refCount: true })
      );
  }

  getById(id: number): Observable<ClientContract> {
    return this.cacheInvalidator$.pipe(
      switchMap(() => this.httpClient.get<ClientContractDto>(`${this.baseUrl}/${id}`)),
      map(dto => new ClientContract(dto)),
      shareReplay({ refCount: true })
    );
  }

  sendCalendarInvite(id: number): Observable<void> {
    return this.httpClient.post<void>(`${this.baseUrl}/${id}/send`, {});
  }

  addContract(dto: ContractSaveDto): Observable<ClientContract> {
    return this.httpClient.post<ClientContractDto>(this.baseUrl, dto).pipe(
      map(data => {
        this.cacheInvalidator$.next();
        return new ClientContract(data);
      }),
      shareReplay({ refCount: true })
    );
  }

  updateContract(id: number, dto: ContractSaveDto): Observable<ClientContract> {
    return this.httpClient.put<ClientContractDto>(`${this.baseUrl}/${id}`, dto).pipe(
      map(data => {
        this.cacheInvalidator$.next();
        return new ClientContract(data);
      })
    );
  }

  requestAdditionalInterpreters(
    id: number,
    dto: AdditionalInterpretersSaveDto
  ): Observable<ClientContract> {
    return this.httpClient
      .post<ClientContractDto>(`${this.baseUrl}/${id}/interpreters`, dto)
      .pipe(
        map(data => {
          this.cacheInvalidator$.next();
          return new ClientContract(data);
        })
      );
  }

  confirmContract(id: number, dto: SelectedInterpreterDto): Observable<ClientContract> {
    return this.httpClient
      .put<ClientContractDto>(`${this.baseUrl}/${id}/confirm`, dto)
      .pipe(
        map(data => {
          this.cacheInvalidator$.next();
          return new ClientContract(data);
        })
      );
  }

  cancelContract(id: number): Observable<ClientContract> {
    return this.httpClient
      .put<ClientContractDto>(`${this.baseUrl}/${id}/cancel`, {})
      .pipe(
        map(data => {
          this.cacheInvalidator$.next();
          return new ClientContract(data);
        })
      );
  }

  markContractAsCancelled(id: number): Observable<ClientContract> {
    return this.httpClient
      .put<ClientContractDto>(`${this.baseUrl}/${id}/mark-cancelled`, {})
      .pipe(
        map(data => {
          this.cacheInvalidator$.next();
          return new ClientContract(data);
        })
      );
  }

  deleteContract(id: number): Observable<void> {
    return this.httpClient.delete<void>(`${this.baseUrl}/${id}`).pipe(
      map(() => {
        this.cacheInvalidator$.next();
      })
    );
  }

  private getContracts(type: string): Observable<ClientContractSummary[]> {
    return this.cacheInvalidator$.pipe(
      switchMap(() =>
        this.httpClient.get<ClientContractSummaryDto[]>(`${this.baseUrl}/${type}`)
      ),
      map(dto => dto.map(data => new ClientContractSummary(data))),
      shareReplay({ refCount: true })
    );
  }
}
