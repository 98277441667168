import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, switchMap, throwError } from 'rxjs';
import { environment } from '../../environments/environment';
import { AuthService, getAccessToken } from '../core/services/auth.service';
import { AuthenticationDataService } from '../data/services/authentication/authentication-data.service';

@Injectable()
export class SessionInterceptor implements HttpInterceptor {
  constructor(
    private router: Router,
    private authenticationDataService: AuthenticationDataService,
    private authService: AuthService
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (
      !request.url.startsWith(environment.apiRoot) ||
      request.url === `${environment.apiRoot}/auth` ||
      request.url === `${environment.apiRoot}/request-password-reset` ||
      request.url === `${environment.apiRoot}/reset-password` ||
      request.url.startsWith(`${environment.apiRoot}/refresh`) ||
      request.url.startsWith(`${environment.apiRoot}/registration`) ||
      request.url === `${environment.apiRoot}/users/user`
    ) {
      return next.handle(request);
    }

    // TODO: Re-add error handling
    if (this.authService.getAccessTokenIfValid() !== undefined) {
      return next.handle(this.attachTokenToRequest(request, getAccessToken()!));
    } else if (this.authService.getRefreshTokenIfValid() !== undefined) {
      return this.authenticationDataService.refreshToken().pipe(
        switchMap(a => {
          return next.handle(this.attachTokenToRequest(request, getAccessToken()!));
        })
      );
    } else {
      if (!this.router.url.endsWith('/registration')) {
        void this.router.navigate(['/login']);
      }
      throw Error('no valid token');
    }
  }

  private attachTokenToRequest(
    request: HttpRequest<any>,
    token: string
  ): HttpRequest<any> {
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`
      }
    });
  }

  private handleRequestError = (error: any): any => {
    if (error.status !== 401) {
      return throwError(() => error);
    }

    if (!this.router.url.endsWith('/registration')) {
      void this.router.navigate(['/login']);
    }
    return throwError(() => error);
  };
}
