import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class IconService {
  constructor(
    private iconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer
  ) {}

  public registerIcons(): void {
    this.addSvgIcon('add');
    this.addSvgIcon('arrow-back');
    this.addSvgIcon('arrow-down');
    this.addSvgIcon('arrow-left');
    this.addSvgIcon('arrow-right');
    this.addSvgIcon('arrow-up');
    this.addSvgIcon('calendar');
    this.addSvgIcon('calendar-check');
    this.addSvgIcon('calendar-info');
    this.addSvgIcon('check');
    this.addSvgIcon('chevron-down');
    this.addSvgIcon('chevron-right');
    this.addSvgIcon('chevron-up');
    this.addSvgIcon('clock');
    this.addSvgIcon('close');
    this.addSvgIcon('danger');
    this.addSvgIcon('edit');
    this.addSvgIcon('external');
    this.addSvgIcon('female');
    this.addSvgIcon('info');
    this.addSvgIcon('info-circle');
    this.addSvgIcon('male');
    this.addSvgIcon('menu');
    this.addSvgIcon('search');
    this.addSvgIcon('settings');
    this.addSvgIcon('trash');
    this.addSvgIcon('ellipsis');
  }

  private addSvgIcon(iconName: string, filename?: string): void {
    filename = !filename ? `assets/icon/${iconName}.svg` : filename;
    this.iconRegistry.addSvgIcon(
      iconName,
      this.domSanitizer.bypassSecurityTrustResourceUrl(filename)
    );
  }
}
