import { LuxonDateAdapter } from '@angular/material-luxon-adapter';
import { DateTime } from 'luxon';
import { Injectable } from '@angular/core';

const PARSE_DATE_FORMATS = ['d.M.yyyy', 'd.M.yy']; // allows also padded day and month
const DISPLAY_DATE_FORMATS = 'dd.MM.yyyy';

@Injectable()
export class DolmatchLuxonDateAdapter extends LuxonDateAdapter {
  public override parse(value: any): DateTime<boolean> | null {
    return super.parse(value, PARSE_DATE_FORMATS);
  }

  public override format(date: DateTime): string {
    return super.format(date, DISPLAY_DATE_FORMATS);
  }
}
