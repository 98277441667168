import { Routes } from '@angular/router';

export const baseRoutes: Routes = [
  {
    path: 'login',
    loadComponent: () => import('./pages/login/login.page').then(m => m.LoginPage)
  },
  {
    path: 'registration',
    loadComponent: () =>
      import('./pages/registration/registration.page').then(m => m.RegistrationPage)
  },
  {
    path: 'request-password-reset',
    loadComponent: () =>
      import('./pages/request-password-reset/request-password-reset.page').then(
        m => m.RequestPasswordResetPage
      )
  },
  {
    path: 'reset-password',
    loadComponent: () =>
      import('./pages/reset-password/reset-password.page').then(m => m.ResetPasswordPage)
  },
  {
    path: 'support',
    loadComponent: () => import('./pages/support/support.page').then(m => m.SupportPage)
  }
];

export const interpreterRoutes: Routes = [
  ...baseRoutes,
  {
    path: '',
    redirectTo: 'pending-contracts',
    pathMatch: 'full'
  },
  {
    path: 'pending-contracts',
    loadComponent: () =>
      import('./pages/interpreter/pending-contracts/pending-contracts.page').then(
        m => m.PendingContractsPage
      )
  },
  {
    path: 'contract-details/:id',
    loadComponent: () =>
      import('./pages/interpreter/contract-details/contract-details.page').then(
        m => m.PendingContractDetailsPage
      )
  },
  {
    path: 'confirmed-contracts',
    loadComponent: () =>
      import('./pages/interpreter/confirmed-contracts/confirmed-contracts.page').then(
        m => m.ConfirmedContractsPage
      )
  },
  {
    path: 'availability',
    loadComponent: () =>
      import('./pages/interpreter/availability/availability.page').then(
        m => m.AvailabilityPage
      )
  },
  {
    path: 'settings',
    loadComponent: () =>
      import('./pages/interpreter/settings/settings.page').then(m => m.SettingsPage)
  },
  {
    path: 'change-password',
    loadComponent: () =>
      import('./pages/interpreter/change-password/change-password.page').then(
        m => m.ChangePasswordPage
      )
  }
];

export const clientRoutes: Routes = [
  ...baseRoutes,
  {
    path: '',
    redirectTo: 'pending-contracts',
    pathMatch: 'full'
  },
  {
    path: 'pending-contracts',
    loadComponent: () =>
      import('./pages/client/pending-contracts/pending-contracts.page').then(
        m => m.PendingContractsPage
      )
  },
  {
    path: 'confirmed-contracts',
    loadComponent: () =>
      import('./pages/client/confirmed-contracts/confirmed-contracts.page').then(
        m => m.ConfirmedContractsPage
      )
  },
  {
    path: 'contract-details/:id',
    loadComponent: () =>
      import('./pages/client/contract-details/contract-details.page').then(
        m => m.AddContractPage
      )
  },
  {
    path: 'settings',
    loadComponent: () =>
      import('./pages/client/settings/settings.page').then(m => m.SettingsPage)
  },
  {
    path: 'change-password',
    loadComponent: () =>
      import('./pages/client/change-password/change-password.page').then(
        m => m.ChangePasswordPage
      )
  }
];

export const adminRoutes: Routes = [
  ...baseRoutes,
  {
    path: '',
    redirectTo: 'interpreters',
    pathMatch: 'full'
  },
  {
    path: 'interpreters',
    loadComponent: () =>
      import('./pages/admin/interpreters/interpreters.page').then(m => m.InterpretersPage)
  },
  {
    path: 'config',
    loadComponent: () =>
      import('./pages/admin/config/config.page').then(m => m.ConfigPage)
  },
  {
    path: 'clients',
    loadComponent: () =>
      import('./pages/admin/clients/clients.page').then(m => m.ClientsPage)
  },
  {
    path: 'admins',
    loadComponent: () =>
      import('./pages/admin/admin-users/admin-users.page').then(m => m.AdminUsersPage)
  },
  {
    path: 'value-lists',
    loadComponent: () =>
      import('./pages/admin/value-lists/value-lists.page').then(m => m.ValueListsPage)
  }
];

export const notLoggedInRoutes: Routes = [
  ...baseRoutes,
  {
    path: '**',
    redirectTo: 'login',
    pathMatch: 'full'
  }
];
